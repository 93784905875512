.App {
  text-align: center;
}

.landingLogo {
  height: 40vmin;
  fill: white;
  pointer-events: none;
  max-width: 100vw;
}

@media (prefers-reduced-motion: no-preference) {
  .landingLogo {
    animation: animateBlur 1s ease-in;
  }
  .email {
    animation: animateEmail 1s ease-out;
  }
}

.screenView {
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &--landing {
    @extend .screenView;
    background-color: navajowhite;
    flex-direction: column;
  }
  &--players {
    @extend .screenView;
    background-color: #fff3e0;
    flex-direction: row;
  }
}
.verticalDivider {
  border-left: 1px solid black;
  height: 500px;
  margin: 0 1%;
}

.email a {
  font-size: calc(10px + 2vmin);
  color: #2A251D;
  text-decoration: none;
}

@keyframes animateEmail {
  0% {
    transform: translateY(75%);
    filter: blur(20px);
    opacity: 0;
  }
  50% {
    transform: translateY(75%);
    filter: blur(20px);
    opacity: 0;
  }
  100% {
    transform: none;
    filter: none;
    opacity: 1;
  }
}

@keyframes animateBlur {
  0% {
    filter: blur(20px);
  }
  50% {
    filter: none;
  }
  100% {
    filter: none;
  }
}
